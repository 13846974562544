.why-us {
  width: 100vw;
  height: auto;
  display: flex;
  justify-content: center;
  padding-top: 5vh;
  padding-bottom: 5vh;
  background: linear-gradient(to left, #fff 50%, #e3e3e3);
}
.why-us .why-us-container {
  width: 90vw;
  display: flex;
  justify-content: space-around;
}

.why-us .why-us-container .why-us-content {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: inherit;
  width: 55vw;
}
.why-us .why-us-container .why-us-content .why-us-section-title h2 {
  font-size: 26px;
  font-weight: 400;
  color: #3691cd;
  /* text-transform: uppercase; */
}
.why-us .why-us-container .why-us-content .why-us-section-title h3 {
  font-size: 42px;
  font-weight: 700;
}
.why-us .why-us-container .why-us-content .button {
  width: 180px;
  border: none;
  background-color: #3691cd;
  color: #fff;
  height: 40px;
  justify-content: center;
  align-items: center;
  display: flex;
  text-decoration: none;
}
.why-us .why-us-container .why-us-image {
  width: 45vw;
  height: 60vh;
  background-image: url(../../assets/img/whyus.jpg);
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}

@media (max-width: 882px) {
  .why-us {
    background-color: #fff !important;
  }
  .why-us .why-us-container {
    flex-direction: column;
    width: 100vw;
  }
  .why-us .why-us-container .why-us-content {
    width: 98vw;
    padding: 10px;
  }
  .why-us .why-us-container .why-us-image {
    width: 100vw;
    height: 80vh;
    background-color: #fff;
  }
}
