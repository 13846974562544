.payment {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 5vh;
  padding-bottom: 10vh;
  background-color: white;
}
.payment img {
  width: 100px;
}
.payment .payment-container {
  width: 500px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.payment .payment-container h1 {
  font-weight: 700;
  margin-top: 2vh;
  text-align: center;
}
.payment .payment-container h3 {
  color: rgb(81, 81, 81);
  font-size: 16px;
  font-weight: 700;
  text-transform: uppercase;
}
.payment .payment-container h3 span {
  font-style: italic;
  font-weight: 400;
}
.payment .payment-container p {
  margin-top: 2vh;
  margin-bottom: 5vh;
  text-align: center;
}
.payment .payment-container form {
  background-color: #f3f3f3;
  display: flex;
  flex-direction: column;
  padding: 20px;
  border-radius: 10px;
  width: 100%;
  box-shadow: 0px 0px 10px rgb(209, 209, 209);
}
.payment .payment-container input {
  outline: none;
  border: 1px solid gray;
  /* margin-top: 10px; */
  margin-bottom: 20px;
  height: 40px;
  padding: 5px;
  border-radius: 10px;
}
.payment .payment-container .input-div {
  display: flex;
  flex-direction: column;
  width: auto;
}
.payment .payment-container .input-div label {
  margin: 0;
  font-size: 12px;
  font-weight: 600;
  color: rgb(88, 88, 88);
}
.payment .payment-container .input-div input {
  width: 100% !important;
}
.payment .payment-container .cohort,
.payment .payment-container .mode {
  display: flex;
  align-items: center;
}
.payment .payment-container .cohort input,
.payment .payment-container .mode input {
  margin-right: 6px;
  margin-bottom: 0;
  width: 20px;
}
.payment .payment-container .mode .online {
  margin-left: 50px;
}
.payment .payment-container .mode {
  width: 100%;
}
.payment .payment-container .mode div {
  display: flex;
  align-items: center;
  margin-top: -10px;
  margin-bottom: 5px;
}
.payment .payment-container .selected-courses {
  margin-bottom: 2vh;
}
.payment .payment-container .selected-courses .selected-course {
  display: flex;
  align-items: center;
}
.payment .payment-container .selected-courses .selected-course input {
  margin: 0;
  width: 18px;
}
.payment .payment-container .selected-courses .selected-course label {
  margin-left: 10px;
}

.payment .payment-container .form-group {
  display: flex;
  width: 100%;
  justify-content: space-between;
}
.payment .payment-container .form-group .input-div {
  width: 49%;
}
.payment .payment-container .form-group.no-of-kids {
  align-items: center;
  justify-content: space-around;
  margin-bottom: 20px;
}

.payment .payment-container .form-group.no-of-kids input {
  width: 100px;
  text-align: center;
  margin: 0;
}
/* .payment .payment-container .cohort {
  border-bottom: 1px rgb(202, 202, 202) solid;
  margin-bottom: 2vh;
} */
.payment .payment-container h4 {
  font-size: 16px;
  text-decoration: underline;
}
.payment .payment-container ul li {
  font-size: 14px;
}
.payment .payment-container .student-detail p {
  text-align: left;
  margin: 0;
  color: gray;
}
.payment .payment-container .student-detail .form-group .age {
  width: 30%;
}
.payment .payment-container .student-detail .form-group .email {
  width: 68%;
}
.payment .payment-container .student-detail .p-number {
  width: 100%;
}
.payment .total {
  margin-top: 3vh;
  font-weight: 600;
  font-size: 26px !important;
  text-align: center;
}
.payment .pay-btn {
  height: 40px;
  border: none;
  background-color: #3691cd;
  color: #fff;
  margin-top: 3vh;
}

@media (max-width: 578px) {
  .payment .payment-container {
    width: 100%;
    padding: 10px;
  }
  .payment .payment-container .form-group {
    flex-direction: column;
  }
  .payment .payment-container .form-group .input-div {
    width: 100%;
  }
  .payment .payment-container form {
    padding: 8px;
  }
}
