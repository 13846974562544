.about {
  width: 100vw;
  height: auto;
  display: flex;
  justify-content: center;

  background-color: white;

  /* background: linear-gradient(to right, white 40%, transparent 40%); */
}
.about .about-container {
  /* width: 90vw; */
  display: flex;
  justify-content: space-between;
  width: 100vw;
}
.about .about-container .about-image {
  width: 30vw;
  height: 60vh;
  padding-top: 5vh;
  padding-bottom: 10vh;
  background-image: url(../../assets/img/about.gif);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  margin-left: 5vw;
}
.about .about-container .about-content {
  padding-top: 5vh;
  padding-bottom: 10vh;
  width: 60vw;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  background: linear-gradient(to right, #ffffff, #e3e3e3);
  padding: 5vh 4vw 10vh;
}

.about .about-container .about-content .about-section-title h2 {
  font-size: 26px;
  font-weight: 400;
  color: #3691cd;
}
.about .about-container .about-content .about-section-title h3 {
  font-size: 42px;
  font-size: 700;
}
.about .about-container .about-content .button {
  width: 220px;
  height: 40px;
  border: none;
  background-color: #3691cd;
  color: #fff;
  justify-content: center;
  align-items: center;
  display: flex;
  text-decoration: none;
}

@media (max-width: 888px) {
  .about .about-container {
    flex-direction: column-reverse;
  }
  .about .about-container .about-image {
    width: 100vw;
    height: 80vh;
  }
  .about .about-container .about-content {
    width: 100vw;
  }
}
