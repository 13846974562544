.registration-form {
  width: 100vw;
  height: auto;
  min-height: 90vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.registration-form .container {
  text-align: center;
  margin-bottom: 5vh;
}
