* {
  margin: 0;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #03002b;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.button {
  border: none;
  background-color: #3691cd;
  color: #fff;
  width: 180px;
  height: 40px;
  display: visible;
  text-decoration: none;
  justify-content: center;
  align-items: center;
  display: flex;
}
