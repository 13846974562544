.questionsslide .question {
  font-size: 18px;
  margin-bottom: 5vh;
  margin-top: 5vh;
  white-space: pre-line;
}

.questionsslide .choice-div {
  display: flex;
  align-items: center;
  padding: 10px;
  border: solid 1px #3691cd;
  background-color: #3691cd1b;
  border-radius: 2px;
  margin-bottom: 1vh;
  transition: 1s;
}
.questionsslide .choice-div:hover {
  cursor: pointer;
  background-color: #3691cd;
  transform: scale(1.01);
}
.questionsslide .choice-div .choice-number {
  width: 25px;
  height: 25px;
  margin-right: 20px;
  display: flex;
  justify-content: center;
  align-content: center;
  background-color: #e3e3e3;
  color: #3691cd;
  border: solid 1px #3691cd;
  transition: 0.4s;
}
.questionsslide .choice-div:hover .choice-number {
  background-color: #3691cdbd;
  color: #fff;
  border: solid 1px #fff;
}
.questionsslide .choice-div .choice {
  height: inherit;
}
.questionsslide .choice-div:hover .choice {
  color: #fff;
  transition: 0.4s;
}

.questionsslide button {
  background-color: #3691cd;
  border: none;
  width: 200px;
  color: #fff;
  height: 50px;
  transition: 0.4s;
}
.questionsslide button:hover {
  transform: scale(1.1);
}

.questionsslide .btns .back {
  background-color: #03002b;
  width: 150px;
  height: 40px;
  margin-left: 20px;
}

@media (max-width: 394px) {
  .questionsslide .btns .back {
    margin-top: 10px;
  }
}
