.offered-courses {
  width: 100vw;
  height: auto;
  background-color: rgb(0, 0, 0);
  color: #fff;
  display: flex;
  /* margin-top: 5vh; */
}
.offered-courses .link {
  text-decoration: none !important;
  color: red;
}
.offered-courses .cta {
  height: 100vh;
  width: 35vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 20px;
}
.offered-courses .cta h2 {
  font-size: 26px;
  font-weight: 400;
}
.offered-courses .cta h3 {
  font-size: 42px;
  font-weight: 700;
  margin-top: 20px;
  margin-bottom: 40px;
}
.offered-courses .cta p {
  color: gray;
  margin-bottom: 40px;
}
.offered-courses .cta .button {
  width: 180px;
  height: 40px;
  border: none;
  background-color: #3691cd;
  color: #fff;
  justify-content: center;
  align-items: center;
  display: flex;
  text-decoration: none;
}

.offered-courses .offered-courses-container {
  height: 100vh;
  width: 74vw;
  background-color: #3691cd;
  background-image: url(../../assets/img/offerbg.jpg);
  border-radius: 10px;
  overflow: hidden;
  background-color: black;
  background-position: center;
  background-size: cover;
  background-attachment: fixed;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  /* padding: 1vw; */
}
.offered-courses .offered-courses-container .row-1 {
  height: 45vh;
  width: 100%;
  /* background-color: red; */
  display: flex;
  justify-content: center;
  align-items: flex-end;
}
.offered-courses .offered-courses-container .row-1 .row-1-container {
  /* background-color: aquamarine; */
  height: 43vh;
  transition: 0.4s;
  width: 98%;
  cursor: pointer;
  border-radius: 10px;
  overflow: hidden;
  background-image: url(../../assets/img/course1.png);
  background-position: top;
  background-size: cover;
  /* box-shadow: 0px 0px 10px #3691CD; */
  display: flex;
  justify-content: flex-end;
  position: relative;
}
.offered-courses .offered-courses-container .row-1 .row-1-container::before {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  content: "";
  background: linear-gradient(to left, black, transparent);
}
.offered-courses
  .offered-courses-container
  .row-1
  .row-1-container
  .class-title {
  width: 40vw;
  padding: 20px;
  text-align: right;
  z-index: 12;
}
.offered-courses
  .offered-courses-container
  .row-1
  .row-1-container
  .class-title
  h2 {
  text-transform: uppercase;
  font-weight: 400;
  font-size: 22px;
  color: #f3f3f3;
}
.offered-courses
  .offered-courses-container
  .row-1
  .row-1-container
  .class-title
  h3 {
  font-weight: 700;
  font-size: 35px;
  color: #e3e3e3;
}

.offered-courses .offered-courses-container .row-2 {
  height: 24vh;
  width: 100%;
  display: flex;
  /* justify-content: space-between; */
  position: relative;
  /* background-color: blue; */
}
.offered-courses .offered-courses-container .row-2 .row-2-col-1 {
  width: 30%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #3691cd;
  background-image: url(../../assets/img/offerbg.jpg);
  border-radius: 10px;
  overflow: hidden;
  background-color: black;
  background-position: center;
  background-size: cover;
  background-attachment: fixed;
}
.offered-courses
  .offered-courses-container
  .row-2
  .row-2-col-1
  .row-2-col-1-container {
  transition: 0.4s;

  cursor: pointer;
  border-radius: 10px;
  overflow: hidden;
  background-image: url(../../assets/img/course4.jpg);
  background-position: center;
  background-size: cover;
  position: relative;
  width: 92%;
  height: 90%;
  /* box-shadow: 0px 0px 10px #3691CD; */
  display: flex;
  align-items: flex-end;
}
.offered-courses
  .offered-courses-container
  .row-2
  .row-2-col-1
  .row-2-col-1-container::before {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  content: "";
  background: linear-gradient(to top, black, transparent);
}

.offered-courses
  .offered-courses-container
  .row-2-col-1
  .row-2-col-1-container
  .class-title {
  /* width: 40vw; */
  padding: 20px;
  text-align: left;
  z-index: 12;
  position: relative;
  padding: 10px;
}
.offered-courses
  .offered-courses-container
  .row-2-col-1
  .row-2-col-1-container
  .class-title
  h2 {
  text-transform: uppercase;
  font-weight: 400;
  font-size: 14px;
  color: #f3f3f3;
}
.offered-courses
  .offered-courses-container
  .row-2-col-1
  .row-2-col-1-container
  .class-title
  h3 {
  font-weight: 700;
  font-size: 22px;
  color: #e3e3e3;
}

.offered-courses .offered-courses-container .row-2 .row-2-col-2 {
  width: 30%;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  /* background-color: red; */
}
.offered-courses
  .offered-courses-container
  .row-2
  .row-2-col-2
  .row-2-col-2-container {
  /* background-color: burlywood; */
  width: 96%;
  transition: 0.4s;

  height: 90%;
  cursor: pointer;
  border-radius: 10px;
  overflow: hidden;
  background-image: url(../../assets/img/course5.jpg);
  background-position: center;
  background-size: cover;
  position: relative;
  display: flex;
  align-items: flex-end;
  /* box-shadow: 0px 0px 10px #3691CD; */
}
.offered-courses
  .offered-courses-container
  .row-2
  .row-2-col-2
  .row-2-col-2-container::before {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  content: "";
  background: linear-gradient(to top, black, transparent);
}

.offered-courses
  .offered-courses-container
  .row-2-col-2
  .row-2-col-2-container
  .class-title {
  width: initial;
  padding: 20px;
  text-align: right;
  z-index: 12;
  padding-bottom: 0px;
  position: relative;
}
.offered-courses
  .offered-courses-container
  .row-2-col-2
  .row-2-col-2-container
  .class-title
  h2 {
  text-transform: uppercase;
  font-weight: 400;
  font-size: 14px;
  color: #f3f3f3;
}
.offered-courses
  .offered-courses-container
  .row-2-col-2
  .row-2-col-2-container
  .class-title
  h3 {
  font-weight: 700;
  font-size: 22px;
  color: #f3f3f3;
}

.offered-courses .offered-courses-container .row-2 .row-2-col-3 {
  width: 41%;
  background-color: #3691cd;
  background-image: url(../../assets/img/offerbg.jpg);
  border-radius: 10px;
  overflow: hidden;
  background-color: black;
  background-position: center;
  background-size: cover;
  background-attachment: fixed;
  position: absolute;
  bottom: 0;
  right: 10px;
  height: 46vh;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.offered-courses
  .offered-courses-container
  .row-2
  .row-2-col-3
  .row-2-col-3-container {
  /* background-color: blueviolet; */
  cursor: pointer;
  transition: 0.4s;

  border-radius: 10px;
  overflow: hidden;
  background-image: url(../../assets/img/course2.png);
  background-position: center;
  background-size: cover;
  width: 97%;
  height: 43vh;
  display: flex;
  align-items: center;
  /* box-shadow: 0px 0px 10px #3691CD; */
  position: relative;
}
.offered-courses
  .offered-courses-container
  .row-2
  .row-2-col-3
  .row-2-col-3-container::before {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  content: "";
  background: linear-gradient(to right, black, transparent);
}
.offered-courses
  .offered-courses-container
  .row-2-col-3
  .row-2-col-3-container
  .class-title {
  /* width: 40vw; */
  padding: 20px;
  text-align: left;
  z-index: 12;
}
.offered-courses
  .offered-courses-container
  .row-2-col-3
  .row-2-col-3-container
  .class-title
  h2 {
  text-transform: uppercase;
  font-weight: 400;
  font-size: 18px;
  color: #f3f3f3;
}
.offered-courses
  .offered-courses-container
  .row-2-col-3
  .row-2-col-3-container
  .class-title
  h3 {
  font-weight: 700;
  font-size: 29px;
  color: #e3e3e3;
}

.offered-courses .offered-courses-container .row-3 {
  height: 30vh;
  width: 100%;
  display: flex;
}

.offered-courses .offered-courses-container .row-3 .row-3-col-1 {
  width: 45%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  background-color: #3691cd;
  background-image: url(../../assets/img/offerbg.jpg);
  border-radius: 10px;
  overflow: hidden;
  background-color: black;
  background-position: center;
  background-size: cover;
  background-attachment: fixed;
}
.offered-courses
  .offered-courses-container
  .row-3
  .row-3-col-1
  .row-3-col-1-container {
  /* background-color: bisque; */
  width: 95%;
  transition: 0.4s;

  height: 92%;
  cursor: pointer;
  border-radius: 10px;
  overflow: hidden;
  background-image: url(../../assets/img/course6.jpg);
  background-position: center;
  background-size: cover;
  position: relative;
  display: flex;
  align-items: flex-end;
  /* box-shadow: 0px 0px 10px #3691CD; */
}
.offered-courses
  .offered-courses-container
  .row-3
  .row-3-col-1
  .row-3-col-1-container::before {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  content: "";
  background: linear-gradient(to right, black, transparent);
}
.offered-courses
  .offered-courses-container
  .row-3
  .row-3-col-1-container
  .class-title {
  /* width: 40vw; */
  padding: 20px;
  text-align: left;
  z-index: 12;
}
.offered-courses
  .offered-courses-container
  .row-3
  .row-3-col-1-container
  .class-title
  h2 {
  text-transform: uppercase;
  font-weight: 400;
  font-size: 16px;
  color: #f3f3f3;
}
.offered-courses
  .offered-courses-container
  .row-3
  .row-3-col-1-container
  .class-title
  h3 {
  font-weight: 700;
  font-size: 26px;
  color: #e3e3e3;
}

.offered-courses .offered-courses-container .row-3 .row-3-col-2 {
  width: 54%;
  height: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  background-color: #3691cd;
  background-image: url(../../assets/img/offerbg.jpg);
  overflow: hidden;
  background-color: black;
  background-position: center;
  background-size: cover;
  background-attachment: fixed;
  position: relative;
}

.offered-courses
  .offered-courses-container
  .row-3
  .row-3-col-2
  .row-3-col-2-container {
  /* background-color: lavenderblush;  */
  width: 98%;
  transition: 0.4s;

  height: 92%;
  cursor: pointer;
  border-radius: 10px;
  overflow: hidden;
  background-image: url(../../assets/img/course3.png);
  background-position: center;
  background-size: cover;
  /* box-shadow: 0px 0px 10px #3691CD; */
  display: flex;
  justify-content: flex-end;
  position: relative;
}
.offered-courses
  .offered-courses-container
  .row-3
  .row-3-col-2
  .row-3-col-2-container::before {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  content: "";
  background: linear-gradient(to bottom, black, transparent);
}

.offered-courses
  .offered-courses-container
  .row-3
  .row-3-col-2-container
  .class-title {
  width: 40vw;
  padding: 20px;
  text-align: center;
  z-index: 12;
}
.offered-courses
  .offered-courses-container
  .row-3
  .row-3-col-2-container
  .class-title
  h2 {
  text-transform: uppercase;
  font-weight: 400;
  font-size: 18px;
  color: #f3f3f3;
}
.offered-courses
  .offered-courses-container
  .row-3
  .row-3-col-2-container
  .class-title
  h3 {
  font-weight: 700;
  font-size: 26px;
  color: #e3e3e3;
}
.offered-courses .offered-courses-container .row-1 .row-1-container:hover,
.offered-courses
  .offered-courses-container
  .row-2
  .row-2-col-1
  .row-2-col-1-container:hover,
.offered-courses
  .offered-courses-container
  .row-2
  .row-2-col-2
  .row-2-col-2-container:hover,
.offered-courses
  .offered-courses-container
  .row-2
  .row-2-col-3
  .row-2-col-3-container:hover,
.offered-courses .offered-courses-container .row-3 .row-3-col-1-container:hover,
.offered-courses
  .offered-courses-container
  .row-3
  .row-3-col-2
  .row-3-col-2-container:hover {
  box-shadow: 0px 0px 10px #3691cd;
  overflow: hidden;
  transform: scale(1.01);
  color: #fff !important;
}

@media (max-width: 1112px) {
  .offered-courses {
    flex-direction: column;
  }
  .offered-courses .cta {
    height: auto;
    width: 100vw;
    align-items: center;
  }
  .offered-courses .offered-courses-container {
    height: auto;
    width: 100vw;
  }
  .offered-courses .offered-courses-container .row-1 {
    height: 55vh;
  }
  .offered-courses .offered-courses-container .row-1 .row-1-container {
    /* background-color: aquamarine; */
    height: 53vh;
  }
  .offered-courses
    .offered-courses-container
    .row-1
    .row-1-container
    .class-title {
    width: 100vw;
  }
  .offered-courses .offered-courses-container .row-2 {
    flex-direction: column;
    height: 48vh;
  }
  .offered-courses .offered-courses-container .row-2 .row-2-col-1 {
    width: 50%;
  }
  .offered-courses .offered-courses-container .row-2 .row-2-col-2 {
    width: 50%;
    justify-content: flex-end;
  }
  .offered-courses .offered-courses-container .row-2 .row-2-col-3 {
    width: 49%;
    height: 48vh;
  }
  .offered-courses
    .offered-courses-container
    .row-2
    .row-2-col-3
    .row-2-col-3-container {
    height: 46vh;
  }
  .offered-courses .offered-courses-container .row-3 {
    flex-direction: column;
    height: 50vh;
  }
  .offered-courses .offered-courses-container .row-3 .row-3-col-1 {
    width: 98%;
  }
  .offered-courses .offered-courses-container .row-3 .row-3-col-2 {
    width: 98%;
  }
  .offered-courses
    .offered-courses-container
    .row-3
    .row-3-col-2-container
    .class-title {
    width: auto;
  }
}

@media (max-width: 992px) {
  .offered-courses .offered-courses-container {
    background-attachment: unset;
  }
}
