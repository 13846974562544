.landingslide h2 {
  font-size: 24px;
  font-weight: 600;
}

.landingslide h3 {
  font-size: 18px;
  font-weight: 500;
  margin-top: 5vh;
  margin-bottom: 5vh;
}
.landingslide button {
  background-color: #3691cd;
  border: none;
  width: 200px;
  color: #fff;
  height: 50px;
  transition: 0.4s;
}
.landingslide button:hover {
  transform: scale(1.1);
}
