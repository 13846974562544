header {
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  height: 8vh;
  width: 100vw;
  background-color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 20px;
  padding-right: 20px;
  transform: translateY(0%);
  transition: 0.4s;
  box-shadow: 0px 10px 10px rgba(78, 78, 78, 0.282);
  z-index: 1999;
}

header .logo {
  color: #03002b;
  font-weight: 700;
  font-size: 26px;
  margin: 0;
}

.hamburger {
  display: none;
  cursor: pointer;
  flex-direction: column;
  justify-content: space-between;
  width: 30px;
  height: 18px;
  transition: 0.3s ease;
}

.hamburger span {
  display: block;
  height: 3px;
  background-color: #333;
  border-radius: 2px;
}

.hamburger.open span:nth-child(1) {
  transform: translateY(8px) rotate(45deg);
}

.hamburger.open span:nth-child(2) {
  opacity: 0;
}

.hamburger.open span:nth-child(3) {
  transform: translateY(-8px) rotate(-45deg);
}

header nav {
  display: flex;
  align-items: center;
}

header nav.open {
  display: block;
}

header nav .navigations {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 50vw;
  margin: 0;
}

header nav .navigations > li {
  list-style: none;
}

header nav .navigations > li a {
  text-decoration: none;
  color: rgb(1, 1, 41);
  padding: 10px;
}

header .nav-option {
  position: relative;
}

header .drops {
  display: flex;
  position: absolute;
  top: 0vh;
  z-index: 2999;
  /* width: max-content; */
}

header .dropdown {
  list-style: none;
  width: auto;
  margin: 0;
  box-shadow: 0px 5px 10px gray;
  /* max-height: 450px; */
  background-color: white;
}

header .dropdown li {
  padding: 10px;
  padding-bottom: 10px;
  transition: 0.4s;
  margin-top: 10px;
  cursor: pointer;
  width: 200px;
  border-bottom: solid 1px #e3e3e3;
}

header li a:hover {
  transform: scale(1.01);
  box-shadow: 0px 5px 10px gray;
  cursor: pointer;
  background-color: #3691cd;
  color: #fff;
}

header li a:hover {
  color: #fff !important;
}

header .drop-dropdown {
  background: #fff;
  box-shadow: 0px 5px 10px gray;
  max-width: 250px;
  display: flex;
  position: absolute;
  left: 100%;
  margin-top: -40px;
  flex-direction: column;
  justify-content: space-around;
}

header .drop-dropdown li {
  /* margin: 10px; */
}

header .button {
  border: none;
  padding: 10px;
  background-color: #3691cd;
  color: #fff;
  width: 180px;
  height: 40px;
  display: visible;
  text-decoration: none;
  justify-content: center;
  align-items: center;
  display: flex;
}

header.downscroll {
  transform: translateY(-100%);
}

@media (max-width: 910px) {
  header {
    justify-content: space-between;
  }

  .hamburger {
    display: flex;
  }

  header nav {
    display: none !important;
    position: fixed;
    top: 8vh;
    right: 0;
    width: 60vw;
    height: auto;
    background-color: #3691cd;
    z-index: 1000;
    transition: 0.3s;
  }

  header nav.open {
    display: flex !important;
  }

  header nav .navigations {
    flex-direction: column;
    justify-content: space-evenly;
    width: 100%;
  }
  .nav-option {
    margin-top: 10px;
    margin-bottom: 10px;
  }
  header .drops {
    position: static;
    width: 100%;
  }

  header .dropdown {
    background-color: white;
    padding-left: 0;
    width: 100%;
  }
  header .drop-dropdown {
    left: -110%;
  }

  header .nav-option a {
    color: #fff;
  }
  header nav ul li a {
    color: #fff;
  }

  header .button {
    display: none;
  }
}
