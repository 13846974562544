.programme {
  background-color: black;
  height: 50vh;
  width: 80vw;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 20px;
  /* padding-right: 20px; */
  padding-top: 10px;
  padding-bottom: 10px;
  color: #fff;
  margin-top: 15vh;
}
.programme-image {
  /* background-image: url(../../../asskets/heroImages/animation.png); */
  background-size: cover;
  background-position: center;
  width: 500px;
  height: 60vh;
  border-radius: 20px;
}
.programme-content {
  width: 50%;
}
.programme-content .programme-category {
  font-size: 20px;
}
.programme-content .programme-title {
  font-size: 36px;
  font-weight: 600;
}
.programme-actions {
  display: flex;
  align-items: center;
}
.programme-actions .booknow-btn {
  margin-right: 50px;
  background-color: #3691cd;
  border: none;
  height: 40px;
  /* width: 180px; */
  padding-left: 10px;
  padding-right: 10px;
  color: #fff;
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
}
.programme-actions .learnmore-btn {
  background: none;
  border: none;
  color: #fff;
  text-decoration: none;
}

@media (max-width: 1194px) {
  .programme {
    width: 95vw;
  }
}
@media (max-width: 1104px) {
  .programme-image {
    width: 460px;
  }
}
@media (max-width: 992px) {
  .programme {
    flex-direction: column;
    min-height: 80vh;
    height: auto;
    padding: 10px;
  }
  .programme-image {
    width: 90%;
    height: 50vh;
    justify-content: space-around;
  }
  .programme-content {
    height: auto;
    width: 90%;
    margin-top: 20px;
    margin-bottom: 10px;
  }
}
@media (max-width: 458px) {
  .programme-actions {
    flex-direction: column-reverse;
  }
  .programme-actions .booknow-btn {
    margin-right: 0px;
  }
  .programme-actions .learnmore-btn {
    margin-bottom: 10px;

    width: 180px;
  }
}
