.courses {
  min-height: 92vh;
  width: 100vw;
  background-position: center;
  background-size: cover;
  position: relative;
  color: #fff;
  display: flex;
  align-items: center;
  flex-direction: column;
  background-attachment: fixed;
}
.courses::before {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.courses .courses-hero {
  z-index: 12;
  position: relative;
  width: 95%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  padding-top: 5vh;
  padding-bottom: 2vh;
}
.courses .courses-hero .courses-hero-left {
  width: 73%;
  height: 100%;
  display: flex;
  flex-direction: column;
}
.courses .courses-hero .courses-hero-left .course-info {
  background-color: #000000b7;
  border-radius: 10px;
  /* border: solid 1px #aaa; */
  box-shadow: 0px 0px 10px gray;
  padding: 20px;
  backdrop-filter: blur(10px);
}
.courses .courses-hero .courses-hero-left .course-info h1 {
  font-weight: 600;
}
.courses .courses-hero .courses-hero-left .details {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #000000b7;
  border-radius: 10px;
  /* border: solid 1px #aaa; */
  box-shadow: 0px 0px 10px gray;
  padding: 20px;
  backdrop-filter: blur(10px);
  margin-top: 2vh;
  margin-bottom: 2vh;
}
.courses .courses-hero .courses-hero-left .details h2 {
  font-size: 24px;
  font-weight: 600;
}
.courses .courses-hero .outline {
  background-color: #000000b7;
  border-radius: 10px;
  /* border: solid 1px #aaa; */
  box-shadow: 0px 0px 10px gray;
  padding: 20px;
  backdrop-filter: blur(10px);
}
.courses .courses-hero .outline h3 {
  font-weight: 700;
  font-size: 24px;
}
.courses .courses-hero .outline ol {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  min-height: 30vh;
}
.courses .courses-hero .outline ol li {
  margin-bottom: 10px;
  max-width: 50%;
}

.courses .courses-hero .courses-hero-right {
  width: 25%;
  height: auto;
  display: flex;
  flex-direction: column;
}
.courses .courses-hero .enroll-div {
  min-height: 45vh;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  background-color: #000000b7;
  border-radius: 10px;
  /* border: solid 1px #aaa; */
  box-shadow: 0px 0px 10px gray;
  padding: 20px;
  backdrop-filter: blur(10px);
  margin-top: 2vh;
}
.courses .courses-hero .enroll-div h3 {
  font-weight: 700;
  font-size: 24px;
}
.courses .courses-hero .payment-div {
  min-height: 50vh;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  padding: 20px;
  background-color: #000000b7;
  border-radius: 10px;
  /* border: solid 1px #aaa; */
  box-shadow: 0px 0px 10px gray;

  backdrop-filter: blur(10px);
}
.courses .courses-hero button {
  margin-bottom: 10px;
  width: 100%;
  transition: 0.4s;
}
.courses .courses-hero button:hover {
  background-color: #fff;
  color: #000;
}

@media (max-width: 992px) {
  .courses .courses-hero {
    flex-direction: column;
  }
  .courses .courses-hero .courses-hero-left {
    width: 100%;
  }
  .courses .courses-hero .courses-hero-right {
    width: 100%;
    margin-top: 2vh;
  }
  .courses .courses-hero .courses-hero-left .details {
    flex-direction: column;
    align-items: flex-start;
  }
  .courses .courses-hero .courses-hero-left .details h2 {
    font-size: 20px;
    font-weight: 600;
    margin-top: 2vh;
  }
}
