.hero {
  width: 100vw;
  height: auto;
  background: linear-gradient(to right, #f3f3f3, #fff);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 10vh;
}

.hero .hero-content {
  width: auto;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-left: 6vw;
  margin-right: 6vw;
}

.hero .hero-content .salutation h2 {
  font-size: 42px;
  font-weight: 400;
  color: #3691cd;
}
.hero .hero-content .salutation h3 {
  font-size: 42px;
  font-weight: 700;
  text-transform: capitalize;
}
.hero .hero-content p {
  color: #4b4b4b;
  margin-top: 5vh;
  margin-bottom: 5vh;
}
.hero .hero-content .button {
  height: 40px;
  width: 180px;
  border: none;
  color: #fff;
  background-color: #3691cd;
  justify-content: center;
  align-items: center;
  display: flex;
  text-decoration: none;
}

.hero .carousel-card {
  margin-top: 6vh;
  perspective: 1000px;
  width: 40vw;
  min-width: 40vw;
  margin-right: 5vw;
  height: 70vh;
  overflow: hidden;
  margin-bottom: 20px;
  border-radius: 10px;
  box-shadow: 0px 0px 30px black;
  background-position: center;
  background-size: cover;
  /* background-image: url(../../../img/hero2.jpeg); */
}

.hero .carousel-card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.6s;
  transform-style: preserve-3d;
  animation: flip 10s infinite;
  background-position: center;
  background-size: cover;
  /* background-image: url(../../../img/hero2.jpeg); */
}

/* @keyframes flip {
  0%,
  100% {
    transform: rotateY(0deg);
  }
  50% {
    transform: rotateY(180deg);
  }
} */

.hero .carousel-card-front,
.hero .carousel-card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  background-position: center;
  background-size: cover;
  box-shadow: 0px 0px 30px black;
  transition: 1s;
  background-position: center;
  background-size: cover;
  /* background-image: url(../../../img/hero2.jpeg); */
}
.hero .carousel-card-front {
  background-position: center;
  background-size: cover;
  /* background-image: url(../../../img/hero2.jpeg); */
}
.hero .carousel-card-front img {
  width: inherit;
}
.hero .carousel-card-back {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  transform: rotateY(180deg); /* Move the initial rotation here */
  background-position: center;
  background-size: cover;
  /* background-image: url(../../../img/hero2.jpeg); */
}
@media (max-width: 910px) {
  .hero {
    padding-bottom: 0vh;
    background: linear-gradient(to right, #f3f3f3af, #ffffffbe);
  }
  .hero .carousel-card {
    position: absolute;
    width: 100vw;
    height: 100vh;
    margin-top: 0vh;
    box-shadow: 0px 0px 0px black;
    margin-right: 0vw;
  }
}
@media (max-width: 844px) {
  .hero {
    height: auto;
    /* margin-bottom: 20vh; */
    justify-content: center;
  }
  .hero .hero-image {
    position: absolute;
    height: 100vh;
  }

  .hero .hero-content {
    width: 100vw;
    min-height: 100vh;
    justify-content: space-evenly;
    margin: 0;
    position: relative;
    z-index: 1;
    background: linear-gradient(
      rgba(255, 255, 255, 0.786),
      white,
      rgba(255, 255, 255, 0.786)
    );
    padding: 20px;
    margin-left: 0vw;
  }
  .hero .hero-content p {
    margin-top: 0;
    margin-bottom: 0;
  }
}
