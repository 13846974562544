.contactform .input-group {
  margin-bottom: 3vh;
}
.contactform .input-group input {
  width: 100%;
  height: 40px;
  padding: 10px;
  border: none;
  outline: none;
  background-color: #03002b2e;

  transition: 0.4s;
}
.contactform .input-group .focus {
  background-color: #3691cd49;
  border-bottom: solid 3px #3691cd;
  height: 60px;
}

.contactform button {
  background-color: #3691cd;
  border: none;
  width: 200px;
  color: #fff;
  height: 50px;
  transition: 0.4s;
  margin-top: 5vh;
}
.contactform button:hover {
  transform: scale(1.1);
}

.contactform .back {
  background-color: #03002b;
  width: 150px;
  height: 40px;
  margin-left: 20px;
}
